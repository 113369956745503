import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import "./Image.scss"

const getStaticImageByFilename = ({ images, src }) => {
  const regex = /\/images\//i
  const edittedSrc = src.replace(regex, "")
  return images?.edges?.find(
    (image) => image?.node?.relativePath === edittedSrc
  )
}

const Image = ({ src, alt, width, height, loading, className, ...rest }) => {
  return (
    <>
      <StaticQuery
        query={graphql`
          {
            images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
              edges {
                node {
                  relativePath
                  name
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
        `}
        render={({ images }) => {
          const image = getStaticImageByFilename({
            images,
            src,
          })

          const imageData = image?.node?.childImageSharp?.gatsbyImageData

          return (
            <picture
              className={classNames("picture", {
                [`${className}`]: className,
              })}
            >
              <GatsbyImage
                loading={loading}
                image={imageData}
                alt={alt}
                style={{ width, height }}
                className="picture__img"
                {...rest}
              />
            </picture>
          )
        }}
      />
    </>
  )
}

Image.defaultProps = {
  width: "auto",
  height: "auto",
  loading: "lazy",
}

export default Image
